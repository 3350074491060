<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getMyIP()">GO<v-icon right dark>mdi-google-maps</v-icon></v-btn>
                            
                    </div>
                </div>

            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Blank',
                disabled: false,
                href: '#',
                },
                {
                text: 'Blank 2',
                disabled: true,
                href: '#',
                },
            ],
            messageLists: [],
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        initialize(){
            

        },

        async getMyIP(){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/test/getMyIP`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                console.log(res.data.result);

            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        }

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>